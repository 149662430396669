<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
<div>
  <b-row>
        <b-card class="mb-1 col-md-6" :title="'Commande #'+order.id">
          <b-card-text>
            <b>Date :</b> {{order.date}}
          </b-card-text>
          <b-card-text>
            <b>Status : </b>

              <b-badge v-if="order.status=='dispatched'" variant="success">Envoyée</b-badge>
              <b-badge v-else-if="order.status=='scanned'" variant="success">Scans effectués</b-badge>
              <b-badge v-else-if="order.status=='completed'" variant="warning">Terminée</b-badge>
              <b-badge v-else-if="order.status=='printing'" variant="warning">En production</b-badge>
              <b-badge v-else-if="order.status=='delivered'" variant="success">Livrée</b-badge>
              <b-badge v-else-if="order.status=='failed'" variant="danger">échouée</b-badge>
              <b-badge v-else-if="order.status=='canceled'" variant="danger">Annulée</b-badge>
              <b-badge v-else variant="danger">error</b-badge>

              <div v-if="displayStatusSelection==false">
                <br />
                <b-button  @click="displayStatusSelection=true">Modifier le status de la commande</b-button>
              </div>
              <div v-else>
              <br />
              <label>Sélectionnez un status :</label>
              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
              />
              <br /><br />
              <b-button @click="updateStatus()">Modifier le status</b-button>
              </div>

            

          </b-card-text>
          <b-card-text v-if="order.woo_order!=null">
            <b>Numéro de suivi :</b> 
            <b-form-input
              id="basicInput"
              v-model="trackingNumber"
           
            />
            <br />
            <b-button @click="updateTracking()">Enregistrer le n° de suivi</b-button>
          </b-card-text>
           <b-card-text>
            <b>Acheteur :</b> {{order.user.name}} {{order.user.surname}}
          </b-card-text>
           <b-card-text v-if="order.address">
            <b>Téléphone :</b> {{order.address.phone}}
          </b-card-text>
           <b-card-text v-if="order.address">
            <b>Adresse de livraison :</b><br />
            {{order.address.address1}} - {{order.address.address2}}<br />
            {{order.address.city}} {{order.address.postcode}}<br />
            {{order.address.country}}
          </b-card-text>
        </b-card>

        <b-card class="mb-1 col-md-6" title="Total">
          <b-card-text>
            <b>Sous-total livraison : </b>{{order.delivery_amount}}€
          </b-card-text>
          <b-card-text v-if="order.promotion!=null">
            <b>Promotion : </b>-{{order.promotion.amount}}%
          </b-card-text>
          <b-card-text>
            <b>TOTAL : </b>
            <span v-if="order.promotion!=null">{{order.amount - ((order.amount/100)*order.promotion.amount).toFixed(2)}}€</span>
            <span v-else>{{order.amount}}€</span>
          </b-card-text>
          <div v-if="order.woo_order!=null">
            <b-button @click="sendInvoiceMail()">Renvoyer le mail de facture</b-button>
            <br /><br />
            <b-button @click="sendTrackingMail()">Renvoyer le mail de suivi</b-button>
          </div>
        </b-card>
   

  </b-row>
  <b-row>
    
    <b-card class="mb-1 col-md-12" title="Note">
      <b-form-group
              label="Note"
              label-for="basicInput"
            >
             
              <b-form-input
              required
                id="basicInput"
                v-model="order.note"
                
                placeholder=""
           
              />
             
              <b-button @click="saveNote()">Enregistrer la note</b-button>
            
            </b-form-group>
      </b-card>
</b-row>

  <b-row>
    
        <b-card class="mb-1 col-md-12" title="Détails commande" v-if="cart_items.length">
           <b-card-text v-for="(order_item, index) in cart_items" :key="order_item.id">
            <!-- <img :src="photos[index]" width="120" /><br /> -->
            <b>Nom du produit : </b>
            <span v-if="order_item.product_variation_item">
              {{order_item.glass.name}} ({{order_item.product_variation_item.name}})
            </span>
            <span v-else>
              {{order_item.glass.name}}
            </span>
            <br />
            <b>Quantité : </b>{{order_item.quantity}}<br />
            
            <div >
              <b>Informations personnalisation</b><br />

              <span v-for="order_item_customization in order_item.order_item_customizations" :key="order_item_customization.id">
                <span v-if="order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color'">Couleur {{order_item_customization.model_part.name}} : {{order_item_customization.color.name}}<br /></span>
                <span v-if="order_item_customization.model_part.type=='texture_color' && order_item_customization.texture">Texture {{order_item_customization.model_part.name}} : {{order_item_customization.texture.name}} <span v-if="order_item_customization.model_part.type=='texture_color' && order_item_customization.texture_style">({{order_item_customization.texture_style.name}})</span><br /></span>
                
              </span>
              <span v-if="order_item.tint">Teinte de verres : {{order_item.tint.name}}<br /></span>
              <span v-if="!order_item.order_item_customizations">

                <span v-if="order_item.glass_type">Type de verre : {{order_item.glass_type.name}}<br /></span>
                <span v-if="order_item.glass_option">Option de verre : {{order_item.glass_option.name}}<br /></span>
                <span v-if="order_item.color">Couleur : {{order_item.color.name}}<br /></span>
                <span v-if="order_item.tint">Teinte de verres : {{order_item.tint.name}}<br /></span>
                <span v-if="order_item.branch">Branche : {{order_item.branch.name}}<br /></span>
                <span v-if="order_item.branch_color">Couleur de Branche : {{order_item.branch_color.name}}<br /></span>
                <span v-if="order_item.branch_texture">Texture de Branche : {{order_item.branch_texture.name}}<br /></span>
                <span v-if="order_item.texture">Texture : {{order_item.texture.name}}<br /></span>
                <span v-if="order_item.texture_style">Style de texture : {{order_item.texture_style.name}}<br /></span>
                <span v-if="order_item.refinement">Affinement : {{order_item.refinement.name}}<br /></span>
                <span v-if="order_item.processing">Traitement : {{order_item.processing.name}}<br /></span>
                
                
              </span>

              <span v-if="order_item.engraving_text">Gravure : {{order_item.engraving_text}}<br /></span>
              <span v-else>Gravure : sans gravure<br /></span>
              
              <div v-if="order_item.scan!=null">
                Scan : {{order_item.scan.name}} <br />
                <b-button  @click="displayScan(order_item.scan.id)">Voir le scan</b-button>
                <b-button @click="$router.push({name:'orderItemView', params: { id: order_item.id }})">Voir le fitting</b-button>
              </div>
              <div v-else-if="order_item.size!=null">
                Taille : {{order_item.size}} <br />
             
              </div>
              <div v-if="order_item.prescription!=null">
                Ordonnance : {{order_item.prescription.name}} (<a @click="displayPrescription(order_item.prescription.id)">Voir</a>)<br />
              </div>
              
              
             
            </div>
            <br />
            <b>Total : </b>{{order_item.amount}}€<br /><br />
          </b-card-text>
        </b-card>

        <ScanPopup :scan_id="currentScan" v-if="displayScanPopup" />
        <PrescriptionPopup :prescription_id="currentPrescription" v-if="displayPrescriptionPopup" />
  </b-row>
</div>
</b-overlay>
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      order:null,
      displayStatusSelection:false,
      statusSelected:null,
      statusOptions:['canceled','failed','completed', 'printing', 'dispatched','delivered'],
      pageLength: 10,
      dir: false,
      photos:null,
      cart_items:[],
      currentScan:null,
      currentPrescription:null,
      displayPrescriptionPopup:false,
      displayScanPopup:false,
      loading:true,
      trackingNumber:''
    }
  },
  methods:{
    async saveNote() {
      this.loading=true
      let params=this.order
      await APIRequest.update('order',this.order.id,params)
      this.loading=false
    },
    displayScan(id) {
      this.currentScan=id
      this.displayScanPopup=true
    },
    displayPrescription(id) {
      this.currentPrescription=id
      this.displayPrescriptionPopup=true
    },
    async getOrder() {
      let order=await APIRequest.detail('orders',this.$route.params.id)
      this.order={}
      this.order=order
    
      this.statusSelected=this.order.status
        console.log('on a lorder')
        console.log(this.order)


        this.trackingNumber=this.order.tracking_number

      if(!this.order.user)
      {
        if(this.order.cart!=null) {
          if(this.order.cart.user!=null) {
            this.order.user={}
            this.order.user.name=this.order.cart.user.name
            this.order.user.surname=this.order.cart.user.surname
          }
          else {
            this.order.user={}
            this.order.user.name=''
            this.order.user.surname='Inconnu'
          }
        }
        else {
          this.order.user={}
          this.order.user.name=''
          this.order.user.surname='Inconnu'
        }
       
      }
      if(!this.order.status)
      {
        if(this.order.promotion!=null) {
          if(this.order.promotion.amount==100) {
            this.order.status="completed"
          }
        }
        else if(this.order.cart!=null) {
          if(this.order.cart.promotion!=null) {
            if(this.order.cart.promotion.amount==100) {
              this.order.status="completed"
            }
          }
        }
      }
     
      this.photos=[]

      if(this.order.cart!=null) {

        for(let i=0;i<this.order.cart.cart_items.length;i++)
        {
          if(this.order.cart.cart_items[i].product_variation_item!=null)
          {
            var photos=this.order.cart.cart_items[i].product_variation_item.photos;
          }
          else
          {
            var photos=this.order.cart.cart_items[i].glass.photos;
          }
          if(photos)
          {
            let photo=photos[0];   
            if(photo!='')
            {
              this.photos[i]=photo
            }
            else{
              this.photos[i]=null
            }
          }
          
        }
        this.cart_items=this.order.cart.cart_items
        console.log('cart items')
        console.log(this.cart_items)

      }
      else {
        for(let i=0;i<this.order.order_items.length;i++)
        {
          if(this.order.order_items[i].product_variation_item!=null)
          {
            var photos=this.order.order_items[i].product_variation_item.photos;
          }
          else
          {
            var photos=this.order.order_items[i].glass.photos;
          }
          if(photos)
          {
            let photo=photos[0];   
            if(photo!='')
            {
              this.photos[i]=photo
            }
            else{
              this.photos[i]=null
            }
          }
          
        }
        this.cart_items=this.order.order_items
      }
     
    },
    async updateTracking() {
      this.loading=true
      let datas=this.order
      
      datas.tracking_number = this.trackingNumber
      await APIRequest.update('orders',this.order.id,datas)


    
      await this.getOrder()
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Le numéro de tracking a bien été modifié !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async updateStatus() {
      this.loading=true
      console.log('status seleced')
      console.log(this.statusSelected)
      console.log(this.order.status)
      if(this.statusSelected != this.order.status)
      {
        let datas=this.order
        datas.status=this.statusSelected
        await APIRequest.update('orders',this.order.id,datas)
        await this.getOrder()
      }
        
      
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Le status a bien été modifié !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async sendInvoiceMail() {
      this.loading=true
      let email = await APIRequest.send_email('invoice',this.order.id)
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Le mail de facturation a bien été renvoyé !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async sendTrackingMail() {
      this.loading=true
      let email = await APIRequest.send_email('tracking',this.order.id)
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Le mail de suivi a bien été renvoyé !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }
    
  },
  watch:{
    
  },
  async mounted()
  {
    emitter.on('hide-popup-scan', function () {
      this.displayScanPopup=false
    }, this);
    emitter.on('hide-popup-prescription', function () {
      this.displayPrescriptionPopup=false
    }, this);
    await this.getOrder()
  
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
